export enum ETlaType {
  EVENT = 'event',
  APP = 'app',
  EXPOSE = 'expose',
}

export enum ETlaUrl {
  STAGE = 'https://tla-stg.tmap.co.kr:8988',
  PROD = 'https://tla.tmap.co.kr:8988',
}

export enum ETlaToken {
  STAGE = '3AF19605D63E',
  PROD = 'F5CD39ECE530',
}

export const TLA_PAGE_ID = '/main/place';
export const TLA_SEARCH_MAIN_RECENT = '/search/history';
export const TLA_SEARCH_MAIN_RECENT_EDIT = '/search/history/edit';
export const TLA_SEARCH_MAIN_RECOMMEND = '/search/recommend';
export const TLA_SEARCH_MAIN_FESTIVAL = '/search/recommend/festival';
export const TLA_SEARCH_MAIN_FAVORITE_POI = '/search/bookmark';
export const TLA_SEARCH_MAIN_FAVORITE_ROUTE = '/search/bookmark/route';
export const TLA_SEARCH_MAIN_ADDRESS = '/search/address';
export const TLA_SEARCH_MAIN_MAP = '/search/map';
export const TLA_SEARCH_RESULT = '/search/result';
export const TLA_SEARCH_NO_RESULT = '/search/noresult';
export const TLA_SEARCH_TYPING = '/search/typing';

export const TLA_PATH_PREFIX = '/api/v1/log/';
export const TLA_QUERY_KEY = 'data';

export const TlaMetaKeys = {
  [ETlaType.APP]: ['app_id', 'service_id'],
  [ETlaType.EXPOSE]: ['app_id', 'service_id', 'page_id', 'page_type', 'section_id'],
  [ETlaType.EVENT]: ['app_id', 'service_id', 'page_id', 'page_type', 'section_id', 'action_id'],
};

// https://docs.google.com/spreadsheets/d/189WUkN7ephQI6RHz5s_LSrhoHsDiuwgXiojsNPrAdyc/edit#gid=695609183

export enum EActionId {
  PAGE_INIT = '',
  AND_BACK_KEY = 'tap.backkey', // 하드웨어 백키
  TAP_BACK = 'tap.back',
  TAP_X = 'top_tap.x',
  TAP_OIL_DISCOUNT = 'tap.oil_discount_link',
  DIMMED = 'tap.other',
  TAP_PLACE_BUTTON = 'tap.place',
  DEEPLINK_OPEN = 'deeplink.open',
  TAP_SHARE = 'tap.share',
}

export enum EDurationActionId {
  INIT_WEBVIEW = 'duration.init_webview',
  MAP_LOADING_TIME = 'duration.load_map',
  INIT_POSITION = 'duration.init_position',
  INIT_RENDER_LIST_FINISH = 'duration.render_list',
  MAP_FIRST_PAINT_TIME = 'duration.load_map_background',
}

export enum EImpressionActionId {
  TNOW_LIST = 'impression.poilist',
  TNOW_BANNER = 'impression.banner',
  TNOW_ZONE_LIST = 'impression.zone_list',
}

export enum EDrawerActionId {
  TAP = 'tap.drawer',
  DRAG_UP = 'drag_up.drawer',
  DRAG_DOWN = 'drag_down.drawer',
}

export enum EListActionId {
  ITEM = 'tap.poilist',
  TAP_TO_TOP = 'tap.to_top',
  FAIL_REFRESH = 'tap.fail_refresh',
  ROUTE_BUTTON = 'tap.direction',
  TAB_ITEM = 'list_tap.poi',
  POI_DETAIL = 'list_tap.poi_detail',
}

export enum ELocationActionId {
  CURRENT_POSITION = 'tap.compass',
  RE_SEARCH = 'tap.re_search',
}

export enum ETNowActionId {
  TAB = 'tap.near_poi_category',
  RADIUS = 'tap.radius',
  REFRESH = 'tap.refresh',
  BANNER = 'tap.banner',
  SLIDER = 'tap.adjust_radius',
  EXTEND_BUTTON = 'tap.extend_radius',
  TOOLTIP = 'tap.tooltip',
}

export enum EMapActionId {
  TAP_MAP = 'tap.map',
  LONG_TAP_MAP = 'longtap.map',
  TAP_MAP_POI = 'tap.map_poi',
  TAP_DOT_MARKER = 'tap.map_dot',
  TAP_FAVORITE_MARKER = 'tap.map_bookmark',
  TAP_RECENT_DESTINATION_MARKER = 'tap.map_recent',
  PANNING_MAP = 'panning.map',
  PINCH_IN_MAP = 'pinchin.map',
  PINCH_OUT_MAP = 'pinchout.map',
  COMPASS = 'tap.cardinal_points',
}

export enum ECalloutActionId {
  INFO = 'tap.info',
  ADD_FAVORITE = 'tap.addbookmark',
  DELETE_FAVORITE = 'tap.deletebookmark',
  SHARE = 'tap.share',
  SET_START = 'tap.setorigin',
  SET_THROUGH = 'tap.setthrough',
  SET_DESTINATION = 'tap.setdestination',
}

export enum EFilterActionId {
  DISTANCE = 'tap.distance',
  SCORE = 'tap.score',
  SCORE_WEEK = 'tap.score_week',
  PRICE = 'tap.price',
  FILTER_ITEM_1 = 'tap.filter_type1',
  FILTER_ITEM_2 = 'tap.filter_type2',
  FILTER_ITEM_3 = 'tap.filter_type3',
  ORDER = 'tap.order',
  FILTER_1 = 'tap.type1',
  FILTER_2 = 'tap.type2',
  FILTER_3 = 'tap.type3',
  OIL_DISCOUNT = 'tap.view_oil_discount',
  PARKING_DISCOUNT = 'tap.view_parking_lot',
}

export const NATIONAL_VALUE = 'national';

export const GA_CODE = 'UA-152858626-3';
export const GA_COOKIE_PREFIX = 'tmap';
export const GA_COOKIE_DOMAIN = 'tmap.co.kr';
export const GA_COOKIE_EXPIRES = 28 * 24 * 60 * 60;

export enum GaPvAction {
  T_NOW = 't_now',
  PLACE_CATEGORY = 'place_category',
  SEARCH = 'search',
}

export enum GaTNowCategory {
  PV_PLACE = 'PV_PLACE',
  EXPOSE_T_NOW = 'EXPOSE_T_NOW',
  EXPOSE_CATEGORY = 'EXPOSE_CATEGORY',
  CLICK_T_NOW = 'CLICK_T_NOW',
  CLICK_CATEGORY = 'CLICK_CATEGORY',
}

export enum EPageType {
  PLACE_CATEGORY = 'PLACE_CATEGORY',
  PLACE_MAIN = 'PLACE_MAIN',
  SEARCH = 'SEARCH',
}

export enum ETLAServiceId {
  SEARCH = 'search_web',
  PLACE = 'place_tab',
  NEARBY = 'nearby_category',
  SEARCH_RECOMMEND = 'search_recommend',
}

export const LOG_PARAM_PREFIX = 'log';
export const LOG_PARAM_SEPARATOR = '_';
export const LOG_PARAM_ALL = 'all';

export enum EMixPanelToken {
  STAGE = '7600c413c33bbd7e3742b10f1a522f0d',
  PROD = 'cafac3a4974386155257d177fc48437a',
}

export enum EToggle {
  ON = 'on',
  OFF = 'off',
}
