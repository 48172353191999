import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {TPlaceItem} from 'ducks/place/types';
import {TNowItem} from 'ducks/tnow/types';
import {TPersonalPlaceItem} from 'ducks/userInfo/types';
import {EMarkerType, TMarker} from 'types/Map';
import {checkIsActiveItem} from 'utils/map';
import usePersonalMarker, {TPersonalMarker} from './usePersonalMarker';
import {filterCustomMarker} from 'utils/marker';
import useMapMarkerConfig from './useMapMarkerConfig';
import {isLocalEnv} from 'constant/Env';
import {Anchor} from '@vsm/vsm';

export const usePlaceMapMarker = (list, currentPoi) => {
  const {
    userPosition,
    favorite,
    recentDestination,
    showFavorite,
    showRecentlyDestination,
    calloutInfo,
    mapStyle,
    tRanking,
  } = useAppSelector((state) => ({
    userPosition: state.map.userPosition,
    showFavorite: isLocalEnv ? true : state.userInfo.showFavorite,
    showRecentlyDestination: isLocalEnv ? true : state.userInfo.showRecentlyDestination,
    favorite: state.userInfo.personalPlace.data.favorites,
    recentDestination: state.userInfo.personalPlace.data.recentDestination,
    calloutInfo: state.userInteraction.calloutInfo,
    mapStyle: state.map.style,
    tRanking: state.tRank.data.list,
  }));

  const personalMarker = usePersonalMarker();
  const {markerStyleConfig} = useMapMarkerConfig();
  const isTmapRankingPage = !!tRanking.length;

  const calloutInfoMarker: Nullable<TMarker<{}>> = useMemo(() => {
    if (!calloutInfo?.markerType) {
      return null;
    }

    const lonLat = {
      lon: calloutInfo.lon,
      lat: calloutInfo.lat,
    };

    if (calloutInfo.personalPoiKey) {
      let isValidItem = true;

      if (calloutInfo.markerType === EMarkerType.ACTIVE_FAVORITE) {
        isValidItem = favorite.some((f) => checkIsActiveItem(f, calloutInfo));
      }

      if (calloutInfo.markerType === EMarkerType.ACTIVE_RECENT_DESTINATION) {
        isValidItem = recentDestination.some((f) => checkIsActiveItem(f, calloutInfo));
      }

      if (!isValidItem) {
        return {
          type: EMarkerType.NONE,
          lonLat,
        };
      }
    }

    if (calloutInfo.publicTransportType) {
      return null;
    }

    return {
      type: calloutInfo.markerType,
      clickable: false,
      lonLat,
      anchor: calloutInfo.markerType === EMarkerType.ACTIVE ? 'bottom' : 'center',
    };
  }, [calloutInfo, favorite, recentDestination]);

  const calloutBusStationMarker = useMemo<Nullable<TMarker<{}>>>(
    () =>
      calloutInfo?.publicTransportType === 'busstop'
        ? {
            type: EMarkerType.ACTIVE_BUS_STATION,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
          }
        : null,
    [calloutInfo]
  );

  const calloutSubwayMarker = useMemo<Nullable<TMarker<{}>>>(
    () =>
      calloutInfo?.publicTransportType === 'subway'
        ? {
            type: EMarkerType.ACTIVE_SUBWAY,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
          }
        : null,
    [calloutInfo]
  );

  const poiMarkers: TMarker<Partial<TPlaceItem | TNowItem>>[] = useMemo(() => {
    const markers = (list || []).map((item, idx) => {
      const isActive = currentPoi === item.listId && !calloutInfo?.markerType;
      const {marker: customMarker, label} = filterCustomMarker(item, isActive, {
        mapStyle,
        markerConfig: markerStyleConfig,
      });

      return {
        properties: item,
        type: isActive ? EMarkerType.ACTIVE : EMarkerType.INACTIVE,
        clickable: true,
        description: label || item.poiName,
        lonLat: {
          lon: item.wgs84CenterX,
          lat: item.wgs84CenterY,
        },
        anchor: isActive ? 'bottom' : 'center',
        children: customMarker,
      };
    });

    return markers;
  }, [list, currentPoi, calloutInfo?.markerType, mapStyle, markerStyleConfig]);

  const currentPositionMarker: Nullable<TMarker<Partial<TPlaceItem | TNowItem>>> = useMemo(
    () =>
      userPosition
        ? {
            properties: {poiId: '-1'},
            type: EMarkerType.CURRENT_POSITION,
            clickable: false,
            description: '현재위치 마커',
            lonLat: userPosition,
            anchor: 'center',
          }
        : null,
    [userPosition]
  );

  const tmapRankingMarker: TMarker<Partial<TPlaceItem | TNowItem>>[] = useMemo(() => {
    const markers = tRanking.map((item, idx) => {
      const isActive = currentPoi === item.listId && !calloutInfo?.markerType;
      const {marker: customMarker, label} = filterCustomMarker(
        {...item, idx, isTampRank: true},
        isActive,
        {
          mapStyle,
          markerConfig: markerStyleConfig,
        }
      );

      return {
        properties: item,
        type: isActive ? EMarkerType.ACTIVE_RANKING : EMarkerType.INACTIVE_RANKING,
        clickable: true,
        description: label || item.poiName,
        lonLat: {
          lon: item.wgs84CenterX,
          lat: item.wgs84CenterY,
        },
        anchor: (isActive ? 'bottom' : 'center') as Anchor,
        children: customMarker,
        rank: item.rank,
      };
    });

    return markers;
  }, [tRanking, currentPoi, calloutInfo?.markerType, mapStyle, markerStyleConfig]);

  const favoriteMarker = useMemo<TPersonalMarker[]>(() => {
    return showFavorite ? personalMarker.favoriteMarker : [];
  }, [personalMarker, showFavorite]);

  const favoriteHomeMarker = useMemo<Nullable<TPersonalMarker>>(() => {
    return showFavorite ? personalMarker.favoriteHomeMarker : null;
  }, [showFavorite, personalMarker]);

  const favoriteOfficeMarker = useMemo<Nullable<TPersonalMarker>>(() => {
    return showFavorite ? personalMarker.favoriteOfficeMarker : null;
  }, [showFavorite, personalMarker]);

  const favoritePublicTransMarker = useMemo<TPersonalMarker[]>(() => {
    return showFavorite ? personalMarker.favoritePublicMarker : [];
  }, [showFavorite, personalMarker]);

  const recentlyDestinationMarker = useMemo<TMarker<Partial<TPersonalPlaceItem>>[]>(() => {
    return showRecentlyDestination ? personalMarker.recentDestinationMarker : [];
  }, [personalMarker, showRecentlyDestination]);

  const markers = useMemo(() => {
    return (
      isTmapRankingPage
        ? [...tmapRankingMarker]
        : [
            ...recentlyDestinationMarker,
            ...favoriteMarker,
            ...favoritePublicTransMarker,
            ...poiMarkers,
            favoriteHomeMarker,
            favoriteOfficeMarker,
          ]
    )
      .filter((v) => !!v)
      .concat(currentPositionMarker ? [currentPositionMarker] : [])
      .concat(calloutInfoMarker ? [calloutInfoMarker] : []);
  }, [
    favoriteMarker,
    favoritePublicTransMarker,
    favoriteHomeMarker,
    favoriteOfficeMarker,
    recentlyDestinationMarker,
    poiMarkers,
    currentPositionMarker,
    calloutInfoMarker,
    tmapRankingMarker,
    isTmapRankingPage,
  ]);

  const priorityIncludeMarker = useMemo(() => {
    return markers.find((m) =>
      [EMarkerType.ACTIVE, EMarkerType.ACTIVE_RANKING].includes(m?.type as EMarkerType)
    );
  }, [markers]);

  return {
    markers,
    poiMarkers,
    currentPositionMarker,
    calloutInfoMarker,
    priorityIncludeMarker,
    calloutBusStationMarker,
    calloutSubwayMarker,
  };
};
