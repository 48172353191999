import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  EAdditionalInfo,
  ETPlaceTab,
  AroundMeApiParams,
  TAroundMeSelectableParams,
  ECategoryType,
  TDiscoveryItem,
  TDiscoveryArea,
  TDiscoveryRegerseGeo,
  TDiscoveryParams,
  ECategoryName,
} from './types';
import {getDefaultApiStatus} from 'utils/apis';
import {StoreState} from 'ducks/store';
import fetcher, {frontmanFetcher} from 'utils/fetcher';
import {API_PATH, FRONTMAN_API_PATH} from 'constant/Api';
import {TVsmPublicTransportType} from 'ducks/userInteraction/types';
import {getPlaceTagList} from 'utils/general';
import {getListName} from 'utils/search';
import {TPlaceItem} from 'ducks/place/types';
import compact from 'lodash/compact';
import values from 'lodash/values';
import {shuffle} from 'utils/lodash';
import {EListMode} from 'types/ListDrawer';

/* 내 주변 선택가능 params 기본값 생성
  아침/오전: 7:00~10:59
  점심: 11:00~13:59
  오후: 14:00~16:59
  저녁: 17:00~21:59
  */
export const getAroundMeDefaultSelectableParams: () => TAroundMeSelectableParams = () => {
  let timeOfDay;
  const hours = new Date().getHours();
  if (hours >= 11 && hours < 14) {
    timeOfDay = 'lunch';
  } else if (hours >= 14 && hours < 17) {
    timeOfDay = 'afternoon';
  } else if (hours >= 17 || hours < 4) {
    timeOfDay = 'dinner';
  } else {
    timeOfDay = 'morning';
  }
  return {
    radius: 1000,
    timeOfDay,
    gender: null,
    age: null,
  };
};

export const aroundMeDefaultSelectableFilters = {
  additionalInfos: [] as EAdditionalInfo[],
  categoryGroups: [] as ECategoryType[],
};

const getAroundMeInitialState = () => {
  return {
    result: getDefaultApiStatus({
      list: [] as TPlaceItem[],
      filteredList: [] as TPlaceItem[],
      requestGeoInfo: {
        regionName1: '',
        regionName2: '',
        regionName3: '',
      },
      requestParams: null as Nullable<AroundMeApiParams>,
    }),
    selectedParams: getAroundMeDefaultSelectableParams(),
    selectedFilters: aroundMeDefaultSelectableFilters,
    sortByDistance: false,
    sortByScore: false,
  };
};

const getDiscoveryInitialState = () => {
  return {
    result: getDefaultApiStatus({
      weekendList: [] as TDiscoveryItem[],
      similarList: [] as TDiscoveryItem[],
      deliciousData: {
        cafes: [] as TDiscoveryItem[],
        restaurants: [] as TDiscoveryItem[],
      },
      tripData: {
        food: [] as TDiscoveryItem[],
        landmark: [] as TDiscoveryItem[],
        cafe: [] as TDiscoveryItem[],
        bar: [] as TDiscoveryItem[],
        hotel: [] as TDiscoveryItem[],
      },
    }),
    tripLoading: false,
    userRegionInfo: null as Nullable<TDiscoveryRegerseGeo>,
    selectedRegionNameInfo: {
      depth1: '',
      depth2: '',
    },
  };
};

const initialState = {
  // 공통
  initialDataLoaded: false,
  currentTab: ETPlaceTab.AROUND_ME,
  currentDrawerListMode: EListMode.TOP,
  isFlickingMoved: false, // 발견 데이터로드 시점 체크용 flag
  uiOptions: {
    contentBottomPadding: 0,
  },
  refreshKey: 0,
  adsTickKey: 0,

  // 내 주변
  aroundMeData: getAroundMeInitialState(),
  // 발견
  discoveryData: getDiscoveryInitialState(),

  regionList: {
    depth1: null as Nullable<TDiscoveryArea[]>,
    depth2: {} as {[key in string]: TDiscoveryArea[]},
  },
};

/**
 * 내 주변
 */
export const fetchAroundMeList = createAsyncThunk(
  'tplacehome/aroundMe',
  async (props: Partial<AroundMeApiParams>, creator?) => {
    const storeState = creator?.getState() as StoreState;
    const currentRequestParams = storeState.tplacehome.aroundMeData.result.data.requestParams;
    const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};

    const params: Partial<AroundMeApiParams> = {
      ...getAroundMeDefaultSelectableParams(),
      destLon: currentRequestParams?.destLon,
      destLat: currentRequestParams?.destLat,
      userRealLat,
      userRealLon,
      featureOr: storeState.tplacehome.aroundMeData.selectedFilters.categoryGroups.join(','),
      ...storeState.tplacehome.aroundMeData.selectedParams,
      ...props,
    };
    if (!params.destLon || !params.destLat) {
      params.destLon = userRealLon;
      params.destLat = userRealLat;
    }

    creator?.dispatch(tplacehomeSlice.actions.setAroundMeRequestParams(params));
    // const response = await fetcher.post(API_PATH.POST_AROUND_ME_LIST, params);
    const aroundMeFetcher = frontmanFetcher.post(FRONTMAN_API_PATH.POST_AROUND_ME_LIST, params);
    const geoFetcher = fetcher.get(API_PATH.GET_REVERSE_GEOCODE, {
      params: {lon: params.destLon, lat: params.destLat},
    });
    const [aroundMeRes, geoRes] = await Promise.all([aroundMeFetcher, geoFetcher]);

    creator?.dispatch(
      tplacehomeSlice.actions.setAroundMeRequestGeoInfo({
        regionName1: geoRes.data.data.regionName1 || '',
        regionName2: geoRes.data.data.regionName2 || '',
        regionName3: geoRes.data.data.regionName3 || '',
      })
    );
    return aroundMeRes.data;
  }
);

const getAroundMeStatePending = (state) => {
  state.aroundMeData.result.loading = true;
  state.aroundMeData.result.error = false;
  state.aroundMeData.result.loaded = false;
};
const getAroundMeStateRejected = (state) => {
  state.aroundMeData.result.loading = false;
  state.aroundMeData.result.error = true;
  state.aroundMeData.result.loaded = true;
};
const getAroundMeStateFulfilled = (state, action) => {
  const data = action.payload.data;
  const now = Date.now();
  const list: TPlaceItem[] = (data?.docs || []).map((v: any) => ({
    ...v,
    poiId: `${v.poiId}`,
    imageInfo: v.imageInfo || [],
    placeTags: getPlaceTagList(v.tag || v),
    distance: v.distance * 1000,
    userRealDistance: v.userRealDistance * 1000,
    listId: `${v.pkey}-${v.poiId}-${now}`,
    listName: getListName(v),
    stationType: (v.stationType || '').toLocaleLowerCase() as TVsmPublicTransportType,
  }));
  state.aroundMeData.result.loading = false;
  state.aroundMeData.result.error = false;
  state.aroundMeData.result.loaded = true;
  state.aroundMeData.result.data.list = shuffle(list);
};

/**
 * 발견
 */
// 사용자 위치 reverse geo
export const fetchDiscoveryUserRegion = createAsyncThunk(
  'tplacehome/userRegion',
  async (props: Partial<{}>, creator?) => {
    const storeState = creator?.getState() as StoreState;
    const {lat, lon} = storeState.map.userPosition || {};
    const params = {lat, lon};
    const response = await fetcher.get(API_PATH.GET_DISCOVERY_REVERSE_GEO, {
      params,
    });
    return response.data;
  }
);

const getDiscoveryDefaultParams = (storeState: StoreState): TDiscoveryParams => {
  const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};
  return {
    regionName1: storeState.tplacehome.discoveryData.userRegionInfo?.region1Name || '',
    rows: 5,
    userLat: userRealLat,
    userLon: userRealLon,
  };
};
const getDiscoveryStatePending = (state) => {
  state.discoveryData.result.loading = true;
  state.discoveryData.result.error = false;
  state.discoveryData.result.loaded = false;
};
const getDiscoveryStateRejected = (state) => {
  state.discoveryData.result.loading = false;
  state.discoveryData.result.error = true;
  state.discoveryData.result.loaded = true;
};
const getDiscoveryStateFulfilled = (state, action) => {
  state.discoveryData.result.loading = false;
  state.discoveryData.result.error = false;
  state.discoveryData.result.loaded = true;

  // 음식점, 카페 정리
  const originDeliciousList = compact(action.payload.deliciousData.data);
  const deliciousData = {
    restaurants: originDeliciousList.filter((item) => item.category === '음식점').slice(0, 4),
    cafes: originDeliciousList.filter((item) => item.category !== '음식점').slice(0, 4), // 음식점이 아닌것은 카페. 확인필요.
  };

  state.discoveryData.result.data.weekendList = compact(action.payload.weekendData.data);
  state.discoveryData.result.data.similarList = compact(action.payload.similarData.data);
  state.discoveryData.result.data.deliciousData = deliciousData;
};
// 전체데이터 가져오기
export const fetchDiscoveryData = createAsyncThunk(
  'tplacehome/discovery',
  async (props: Partial<{}>, creator?) => {
    const storeState = creator?.getState() as StoreState;
    const params = getDiscoveryDefaultParams(storeState);

    const [weekendRes, similarRes, deliciousRes] = await Promise.all([
      frontmanFetcher.get(FRONTMAN_API_PATH.GET_DISCOVERY_WEEKEND_LIST, {
        params: {...params, rows: 4},
      }),
      frontmanFetcher.get(FRONTMAN_API_PATH.GET_DISCOVERY_SIMILAR_LIST, {params}),
      frontmanFetcher.get(FRONTMAN_API_PATH.GET_DISCOVERY_DELICIOUS_LIST, {params}),
      creator?.dispatch(fetchTripData({})),
    ]);

    return {
      weekendData: weekendRes.data,
      similarData: similarRes.data,
      deliciousData: deliciousRes.data,
    };
  }
);
// 여행 데이터 가져오기
export const fetchTripData = createAsyncThunk(
  'tplacehome/trip',
  async (props: Partial<{}>, creator?) => {
    const storeState = creator?.getState() as StoreState;
    const selectedNameInfo = storeState.tplacehome.discoveryData.selectedRegionNameInfo;
    const userRegionInfo = storeState.tplacehome.discoveryData.userRegionInfo;
    const params = getDiscoveryDefaultParams(storeState);
    // 여행은 카테고리용 regionName을 보내야 한다.
    params.regionName1 =
      selectedNameInfo.depth1 || userRegionInfo?.foundAreaInfo.defaultRegion1Name || '';
    if (selectedNameInfo.depth2) {
      params.regionName2 = selectedNameInfo.depth2;
    }
    params.rows = 10;

    const res = await frontmanFetcher.get(FRONTMAN_API_PATH.GET_DISCOVERY_TRIP_LIST, {
      params: {...params, categories: values(ECategoryName).join(',')},
    });
    const allList = compact(res.data.data);

    return {
      food: allList.filter((item) => item.category === ECategoryName.FOOD).slice(0, 10),
      landmark: allList.filter((item) => item.category === ECategoryName.LANDMARK).slice(0, 10),
      cafe: allList.filter((item) => item.category === ECategoryName.CAFE).slice(0, 10),
      bar: allList.filter((item) => item.category === ECategoryName.BAR).slice(0, 10),
      hotel: allList.filter((item) => item.category === ECategoryName.HOTEL).slice(0, 5),
    };
  }
);
// 지역리스트
export const fetchRegionListDepth1 = createAsyncThunk(
  'tplace/region1',
  async (props: Partial<{}>, creator?) => {
    const params = {
      regionDepth: 1,
      placeYn: 'Y',
    };
    const res = await fetcher.get(API_PATH.GET_DISCOVERY_REGION_LIST, {params});
    return res.data;
  }
);
export const fetchRegionListDepth2 = createAsyncThunk(
  'tplace/region2',
  async (parentId: string, creator?) => {
    const params = {
      regionDepth: 2,
      placeYn: 'Y',
      parentRegionId: parentId,
    };
    const res = await fetcher.get(API_PATH.GET_DISCOVERY_REGION_LIST, {params});
    return {
      data: res.data,
      parentId,
    };
  }
);

/**
 * create slice
 */
const tplacehomeSlice = createSlice({
  name: 'tplacehome',
  initialState,
  reducers: {
    setInitialDataLoaded(state) {
      state.initialDataLoaded = true;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setCurrentDrawerListMode(state, action: PayloadAction<EListMode>) {
      state.currentDrawerListMode = action.payload;
    },
    setIsFlickingMoved(state) {
      state.isFlickingMoved = true;
    },
    setContentBottomPadding(state, action) {
      state.uiOptions.contentBottomPadding = action.payload || 0;
    },
    updatePlaceHomeRefreshKey(state) {
      state.refreshKey = Date.now();
    },
    updatePlaceAdsTickKey(state) {
      state.adsTickKey = Date.now();
    },
    resetContentData(state) {
      state.aroundMeData = getAroundMeInitialState();
      state.discoveryData = getDiscoveryInitialState();
    },
    /**
     * 내 주변
     */
    // 내 주변 params
    setAroundMeRequestParams(state, action) {
      state.aroundMeData.result.data.requestParams = action.payload;
    },
    setAroundMeRequestGeoInfo(state, action) {
      state.aroundMeData.result.data.requestGeoInfo = action.payload;
    },
    updateAroundMeRequestParams(state, action) {
      state.aroundMeData.selectedParams = {
        ...state.aroundMeData.selectedParams,
        ...action.payload,
      };
    },
    setAroundMeSelectedParams(state, action: PayloadAction<TAroundMeSelectableParams>) {
      state.aroundMeData.selectedParams = {...state.aroundMeData.selectedParams, ...action.payload};
    },
    resetAroundMeSelectedParams(state) {
      state.aroundMeData.selectedParams = getAroundMeDefaultSelectableParams();
    },
    // 내 주변 filters
    setAroundMeFilteredList(state, action) {
      state.aroundMeData.result.data.filteredList = action.payload;
    },
    setAroundMeSelectedFilters(state, action) {
      state.aroundMeData.selectedFilters = {
        ...state.aroundMeData.selectedFilters,
        ...action.payload,
      };
    },
    resetAroundMeSelectedFilters(state) {
      state.aroundMeData.selectedFilters = aroundMeDefaultSelectableFilters;
    },
    toggleAdditionalInfo(state, action: PayloadAction<EAdditionalInfo>) {
      const currentList = state.aroundMeData.selectedFilters.additionalInfos;
      const currentActive = currentList.includes(action.payload);
      let result;
      if (currentActive) {
        result = currentList.filter((item) => item !== action.payload);
      } else {
        result = [...currentList, action.payload];
      }
      state.aroundMeData.selectedFilters.additionalInfos = result;
    },
    toggleCategoryGroup(state, action: PayloadAction<ECategoryType>) {
      const currentList = state.aroundMeData.selectedFilters.categoryGroups;
      const currentActive = currentList.includes(action.payload);
      let result;
      if (currentActive) {
        result = currentList.filter((item) => item !== action.payload);
      } else {
        result = [...currentList, action.payload];
      }
      state.aroundMeData.selectedFilters.categoryGroups = result;
    },
    // 내 주변 거리순
    toggleAroundMeSortByDistance(state) {
      if (!state.aroundMeData.sortByDistance && state.aroundMeData.sortByScore) {
        state.aroundMeData.sortByScore = false;
      }

      state.aroundMeData.sortByDistance = !state.aroundMeData.sortByDistance;
    },
    // 내 주변 지금 가는순
    toggleAroundMeSortByScore(state) {
      if (!state.aroundMeData.sortByScore && state.aroundMeData.sortByDistance) {
        state.aroundMeData.sortByDistance = false;
      }

      state.aroundMeData.sortByScore = !state.aroundMeData.sortByScore;
    },
    /**
     * 발견
     */
    setDiscoveryTripRegionName(state, action: PayloadAction<{depth1: string; depth2: string}>) {
      state.discoveryData.selectedRegionNameInfo.depth1 = action.payload.depth1 || '';
      state.discoveryData.selectedRegionNameInfo.depth2 = action.payload.depth2 || '';
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAroundMeList.pending, getAroundMeStatePending)
      .addCase(fetchAroundMeList.fulfilled, getAroundMeStateFulfilled)
      .addCase(fetchAroundMeList.rejected, getAroundMeStateRejected)

      .addCase(fetchDiscoveryData.pending, getDiscoveryStatePending)
      .addCase(fetchDiscoveryData.rejected, getDiscoveryStateRejected)
      .addCase(fetchDiscoveryData.fulfilled, getDiscoveryStateFulfilled)

      .addCase(fetchTripData.pending, (state, action) => {
        state.discoveryData.tripLoading = true;
      })
      .addCase(fetchTripData.rejected, (state, action) => {
        state.discoveryData.tripLoading = false;
      })
      .addCase(fetchTripData.fulfilled, (state, action) => {
        state.discoveryData.tripLoading = false;
        state.discoveryData.result.data.tripData = action.payload;
      })

      // 사용자 위치 정보 가져왔을때
      .addCase(fetchDiscoveryUserRegion.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.discoveryData.userRegionInfo = data;
      })

      // 지역 리스트 1
      .addCase(fetchRegionListDepth1.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.regionList.depth1 = data;
      })
      // 지역 리스트 2
      .addCase(fetchRegionListDepth2.fulfilled, (state, action) => {
        const parentId = action.payload.parentId;
        const data = action.payload.data.data;
        state.regionList.depth2[parentId] = data || [];
      });
  },
});

export default tplacehomeSlice;
