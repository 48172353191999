import ImageLoader from '../../ImageLoader';
import TmdsTag from '../../@tmds/TmdsTag';
import DiscoveryPoiItemInfo from './DiscoveryPoiItemInfo';
import {IcStar} from '../../@tmds/icons/v1.2/IcStar';
import {ECategoryName, TDiscoveryItem} from '../../../ducks/tplacehome/types';
import {useCallback, useMemo} from 'react';
import {useAppSelector} from '../../../ducks/hooks';
import classNames from 'classnames';
import {IcStarFill} from '../../@tmds/icons/v1.2/IcStarFill';

import s from '../../../styles/components/tplacehome/TPlaceDiscoveryRowPoiltem.module.scss';
import usePlaceHome from 'hooks/usePlaceHome';

const TAG_LIMIT_WINDOW_WIDTH = 600;
const MAX_VISIBLE_TAGS = 3;

type TProps = {
  data: TDiscoveryItem;
  isCategoryTag?: boolean;
  onClickDetail?: () => void;
};

const DiscoveryRowPoiItem = ({data, isCategoryTag, onClickDetail}: TProps) => {
  const {poiName, imageUrl, reviewDto, svcCategoryName, category, tags, distance} = data;
  const {reviewCount, reviewGrade} = reviewDto;
  const {isParking, isValet, isTMapPopular, isTVrestaurant, isReservation, isWaiting} = tags;

  const {width: windowWidth} = useAppSelector((state) => state.layout.windowSize);
  const appSize = useAppSelector((state) => state.layout.appSize);

  const placehomeHook = usePlaceHome();
  const isFavorite = useMemo(() => placehomeHook.checkFavorite(data), [data, placehomeHook]);
  const handleToggleFavorite = useCallback(() => {
    placehomeHook.sendEventDiscovery('tap.bookmark', {
      pkey: data.pkey,
      on: isFavorite,
    });
    placehomeHook.toggleFavorite(data);
  }, [data, isFavorite, placehomeHook]);

  const handleMoveToDetail = useCallback(() => {
    onClickDetail?.();
    placehomeHook.openPoiDetail(data);
  }, [data, onClickDetail, placehomeHook]);

  const tagCategory = useMemo(() => {
    const obj = {name: category, color: 'orange500'};

    switch (category) {
      case ECategoryName.FOOD:
        return {...obj, name: '맛집'};
      case ECategoryName.LANDMARK:
        return {name: '명소', color: 'green600'};
      case ECategoryName.HOTEL:
        return {name: '숙소', color: 'green600'};
      default:
        return obj;
    }
  }, [category]);

  const tagInfoList = useMemo(() => {
    const result: {label: string; color?: string; backgroundColor?: string}[] = [];
    if (isTMapPopular) {
      result.push({
        color: 'textSuccessBolder',
        backgroundColor: 'backgroundSuccessSecondary',
        label: '티맵인기',
      });
    }
    if (isTVrestaurant) {
      result.push({
        color: 'tmobiTeal600',
        backgroundColor: 'tmobiTeal100',
        label: 'TV맛집',
      });
    }
    if (isReservation) {
      result.push({label: '예약'});
    }
    if (isWaiting) {
      result.push({label: '웨이팅'});
    }
    if (isParking) {
      result.push({label: '주차'});
    }
    if (isValet) {
      result.push({label: '발렛'});
    }
    return {result};
  }, [isParking, isValet, isTMapPopular, isTVrestaurant, isReservation, isWaiting]);

  return (
    <div className={s.wrap}>
      <button type="button" className={s.content} onClick={handleMoveToDetail}>
        <div className={s.thumb}>
          <ImageLoader src={imageUrl} toggleClass={s.fade_in} alt={`${poiName} 이미지`} />
          {isCategoryTag && category && (
            <TmdsTag
              label={tagCategory.name}
              type={'bold'}
              backgroundColor={tagCategory.color}
              color={'wbWhite'}
              fontSize={12}
              className={s.tag}
              filled
            />
          )}
        </div>
        <div className={s.info}>
          <em className={s.name}>{poiName}</em>
          <DiscoveryPoiItemInfo
            starPoint={reviewGrade}
            reviewCount={reviewCount}
            category={svcCategoryName}
            distance={distance}
          />
          {tagInfoList.result.length > 0 && (
            <div className={s.tags}>
              {tagInfoList.result.map((tag, index) => {
                if (
                  (appSize.isLandscape || windowWidth <= TAG_LIMIT_WINDOW_WIDTH) &&
                  tagInfoList.result.length >= MAX_VISIBLE_TAGS + 1 &&
                  index >= MAX_VISIBLE_TAGS
                ) {
                  return index === MAX_VISIBLE_TAGS ? (
                    <TmdsTag
                      key={index}
                      label={`+${tagInfoList.result.length - MAX_VISIBLE_TAGS}`}
                      color={'gray600'}
                      backgroundColor={'gray50'}
                      className={s.tag}
                      filled
                    />
                  ) : null;
                }

                return (
                  <TmdsTag
                    key={index}
                    label={tag.label}
                    color={tag.color || 'gray600'}
                    backgroundColor={tag.backgroundColor || 'gray50'}
                    className={s.tag}
                    filled
                  />
                );
              })}
            </div>
          )}
        </div>
      </button>
      <button
        type="button"
        className={classNames(s.favorite, {[s.no_tag]: tagInfoList.result.length === 0})}
        onClick={handleToggleFavorite}
      >
        {isFavorite ? (
          <IcStarFill width={24} height={24} color={'yellow400'} />
        ) : (
          <IcStar width={24} height={24} color={'gray300'} />
        )}
      </button>
    </div>
  );
};

export default DiscoveryRowPoiItem;
